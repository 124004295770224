import React from 'react';

import Image from 'next/image';

import { StyledButton, StyledText } from '@/components/lib';
import { StyledErrContainer } from './InternalServerError.styles';

import magnifyingGlassIllustration from '@/public/assets/overview/magnifying-glass-illustration.svg';

import { constants } from '../appFilters.constants';

const { imgAlt, title, buttonLabel } = constants.internalServerErr;

export const InternalServerError = ({ isFetched, isError }: { isFetched?: boolean; isError: boolean }) => {
  if (!isFetched || !isError) {
    return <></>;
  }

  return (
    <StyledErrContainer>
      <Image height={260} width={260} src={magnifyingGlassIllustration} alt={imgAlt} />
      <StyledText text={title} type="h1" color="primary" />
      <StyledButton label={buttonLabel} onClick={() => window.location.reload()} variant="outlined" />
    </StyledErrContainer>
  );
};
