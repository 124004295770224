import { Stack, styled, alpha } from '@mui/material';

import { StyledContainer, StyledText } from '@/components/lib';

import { fadeInAnimation } from '@/helpers/animations';

export const SamplesNoVersionSelectedContainer = styled(StyledContainer)({
  width: '100%',
  height: '100%',
  margin: 'auto',
  marginTop: '27vh',
  alignItems: 'center',
  justifyContent: 'center',
  animation: `${fadeInAnimation} 0.5s ease`,
  gap: 0
});

export const SamplesNoVersionSelectedButton = styled(StyledText)(({ theme }) => ({
  color: theme.palette.primary.main,
  display: 'inline',
  marginLeft: '4px',
  textDecoration: 'underline',
  cursor: 'pointer'
}));

export const StyledImageContainer = styled(Stack)(({ theme }) => ({
  background: alpha(theme.palette.primary.main, 0.2),
  marginBottom: '32px',
  width: '80px',
  height: '80px',
  borderRadius: '48px',
  justifyContent: 'center',
  alignItems: 'center',

  '& svg': {
    height: '48px',
    width: '48px'
  }
}));

export const StyledTitleText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.grey[400]
}));

export const StyledSubTitleText = styled(StyledText)(({ theme }) => ({
  color: theme.palette.grey[300],
  maxWidth: '250px',
  textAlign: 'center',
  marginTop: '4px'
}));
