import React, { useState } from 'react';

import { getMarkedText } from '@/helpers/utils/getMarkedText';
import { GeneralMetadataChunk, PerPropertySchema, TextEntrySchema } from '@/helpers/services/api';

import {
  SamplesDetailsBodyInfoRetrievalItemContainer,
  StyledInfoRetrievalText
} from './SamplesDetailsBodyInfoRetrievalItem.styles';

interface SamplesDetailsBodyInfoRetrievalItemProps {
  index: number;
  isTranslation?: boolean;
  infoRetrievalItem: TextEntrySchema;
  markingDetails?: PerPropertySchema;
}

const constants = {
  documentTitle: (number: number) => `Document #${number}`
};

const { documentTitle } = constants;

export const SamplesDetailsBodyInfoRetrievalItem = (props: SamplesDetailsBodyInfoRetrievalItemProps) => {
  const { infoRetrievalItem, isTranslation, index, markingDetails } = props;

  const [isOpen, setIsOpen] = useState(false);

  const markingDetailsByIndex = markingDetails
    ? {
        ...markingDetails,
        metadata: Array?.isArray(markingDetails?.metadata)
          ? (markingDetails?.metadata?.filter(item => item?.document_index === index) as GeneralMetadataChunk[]) // Choose the relevant index marking details from the metadata
          : []
      }
    : undefined;

  return (
    <SamplesDetailsBodyInfoRetrievalItemContainer
      translation={!!isTranslation}
      onClick={() => setIsOpen(!isOpen)}
      is_collapse={isOpen.toString()}
      data-testid={documentTitle(index + 1)}
    >
      <StyledInfoRetrievalText
        type="bodyBold"
        is_margin="true"
        text={documentTitle(index + 1)}
        is_collapse={isOpen.toString()}
      />
      <StyledInfoRetrievalText
        type="body"
        includeCopy={!isOpen}
        copyOnTopPosition={true}
        is_collapse={isOpen.toString()}
        text={getMarkedText(infoRetrievalItem.data, markingDetailsByIndex)}
      />
    </SamplesDetailsBodyInfoRetrievalItemContainer>
  );
};
