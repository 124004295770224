import React from 'react';

import { TranslateOutlined } from '@mui/icons-material';

import { StyledText } from '@/components/lib';
import { SamplesTranslationSelectionContainer } from './SamplesTranslationSelection.styles';

import { InteractionTranslatedTextEntriesSchema } from '@/helpers/services/api';

const constants = {
  changeViewBtnLabel: (isTranslation: boolean) => (isTranslation ? 'Show Original Text' : 'Show Translated Text')
};

interface SamplesTranslationSelectionProps {
  isTranslation: boolean;
  translation: InteractionTranslatedTextEntriesSchema;
  setIsTranslation: (isTranslation: boolean) => void;
}

const { changeViewBtnLabel } = constants;

export const SamplesTranslationSelection = (props: SamplesTranslationSelectionProps) => {
  const { translation, isTranslation, setIsTranslation } = props;

  const isTranslated = translation?.information_retrieval?.length || translation?.input || translation?.output;

  if (!isTranslated) {
    return null;
  }

  return (
    <SamplesTranslationSelectionContainer
      translation={!!isTranslation}
      onClick={() => setIsTranslation(!isTranslation)}
    >
      <TranslateOutlined fontSize="small" />
      <StyledText type="tinyBold" text={changeViewBtnLabel(isTranslation)} />
    </SamplesTranslationSelectionContainer>
  );
};
