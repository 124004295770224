import { styled, Link } from '@mui/material';

import { StyledText } from '@/components/lib';

export const StyledSamplesDetailsBodyItemTag = styled(StyledText)(({ theme }) => ({
  fontWeight: 700,
  fontSize: '14px',
  padding: '0 8px',
  borderRadius: '4px',
  width: 'fit-content',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  color: theme.palette.grey[600],
  background: theme.palette.grey[200]
}));

export const StyledSamplesDetailsBodyItemTagLink = styled(Link)(({ theme }) => ({
  fontWeight: 700,
  padding: '0 8px',
  borderRadius: '4px',
  width: 'fit-content',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  color: theme.palette.primary.main,
  background: theme.palette.grey[200],

  '& > .prefix': {
    color: theme.palette.grey[600]
  }
}));
