import { styled, Stack } from '@mui/material';

import { StyledText } from '@/components/lib';

export const StyledInfoRetrievalText = styled(StyledText)<{ is_margin?: string; is_collapse?: string }>(
  ({ theme, is_margin, is_collapse }) => ({
    color: theme.palette.grey[600],
    marginBottom: is_margin === 'true' && is_collapse !== 'true' ? '16px' : 0,
    overflow: is_collapse === 'true' ? 'hidden' : 'visible',
    WebkitLineClamp: is_collapse === 'true' ? 1 : 'auto',
    WebkitBoxOrient: 'vertical',
    display: '-webkit-box',
    whiteSpace: 'break-spaces !important',
    minWidth: '119px'
  })
);

export const SamplesDetailsBodyInfoRetrievalItemContainer = styled(Stack)<{
  is_collapse: string;
  translation?: boolean;
}>(({ translation, is_collapse, theme }) => ({
  background: theme.palette.grey[100],
  padding: '16px',
  cursor: 'pointer',
  transition: '0.4s',
  borderRadius: '12px',
  height: 'fit-content',
  flexDirection: is_collapse === 'true' ? 'row' : 'column',
  border: `2px solid ${translation ? theme.palette.primary?.main : 'transparent'}`,

  '&:hover': {
    background: theme.palette.grey[200],
    borderColor: theme.palette.grey[300]
  }
}));
