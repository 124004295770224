import { Stack, styled } from '@mui/material';

import { StyledButton } from '@/components/lib';
import { paletteOptions } from '@/components/lib/theme/palette';

import { fadeInAnimation } from '@/helpers/animations';

interface SampleDetailsDialogBodyContainerProps {
  isNoData?: boolean;
}

export const SampleDetailsDialogBodyContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isNoData'
})<SampleDetailsDialogBodyContainerProps>(({ isNoData }) => ({
  gap: '7px',
  padding: '0',
  height: '600px',
  display: 'grid',
  gridTemplateColumns: isNoData ? 'auto' : '250px auto',

  '@media (max-height: 790px)': {
    height: '70vh'
  },

  '@media (max-height: 610px)': {
    height: '67vh'
  }
}));

export const SampleDetailsDialogSelectionsContainer = styled(Stack)(({ theme }) => ({
  gap: '8px',
  width: 'auto',
  padding: '8px 16px 8px 8px',
  overflow: 'auto',
  borderRight: `1px solid ${theme.palette.grey[200]}`,
  animation: `${fadeInAnimation} 0.5s ease`
}));

export const StyledCompareBtnContainer = styled(Stack)({
  marginTop: 'auto'
});

export const SampleDetailsDialogSelectionsComparisonButton = styled(StyledButton)(({ theme }) => ({
  border: 'none',

  '&:hover': { border: 'none' },
  '&:disabled': {
    border: 'none',
    color: theme.palette.grey[400]
  },
  '@media (min-width:1440px)': {
    padding: '7px 18px'
  }
}));

interface SampleDetailsDialogSelectionsInnerContainerProps {
  count?: number;
}

export const SampleDetailsDialogSelectionsInnerContainer = styled(
  Stack
)<SampleDetailsDialogSelectionsInnerContainerProps>(({ count, theme }) => ({
  height: 'auto',
  overflow: 'auto',
  marginTop: '-8px',
  paddingTop: '6px',
  position: 'relative',

  '&::after': {
    content: '""',
    position: 'absolute',
    left: '0px',
    top: '0px',
    height: count && count > 0 ? `8px` : 0,
    borderLeft: `2px solid ${theme.palette.grey[200]}`
  }
}));

interface SampleDetailsDialogSelectionsRowContainerProps {
  border?: string;
}

export const SampleDetailsDialogSelectionsRowContainer = styled(Stack)<SampleDetailsDialogSelectionsRowContainerProps>(
  ({ border, theme }) => ({
    width: '100%',
    flexDirection: 'row',
    borderLeft: border ? `2px solid ${theme.palette.grey[200]}` : 'none',
    position: 'relative',

    '&:last-child': {
      borderLeft: 'none',

      '&::after': {
        content: '""',
        position: 'absolute',
        left: '0px',
        top: '0px',
        height: '28px',
        borderLeft: `2px solid ${theme.palette.grey[200]}`
      }
    }
  })
);

interface SampleDetailsDialogBodySelectionContainerProps {
  selected?: boolean;
  llmCallErr?: boolean;
  important?: boolean;
}

export const SampleDetailsDialogBodySelectionContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'selected' && prop !== 'llmCallErr' && prop !== 'important'
})<SampleDetailsDialogBodySelectionContainerProps>(({ selected, llmCallErr, important, theme }) => ({
  height: '58px',
  padding: '8px',
  fontWeight: 700,
  cursor: 'pointer',
  transition: '0.3s',
  justifyContent: 'center',
  borderRadius: important ? '8px 8px 8px 0' : '8px',
  width: important ? '220px' : '200px',
  border: selected
    ? `2px solid ${theme.palette.primary.main}`
    : `2px solid ${important ? theme.palette.grey[200] : 'transparent'}`,

  ':hover': {
    background: theme.palette.grey[100],
    borderColor: selected ? theme.palette.primary.main : theme.palette.grey[200],

    '.MuiTypography-root': { color: theme.palette.grey[900] }
  },

  '.MuiTypography-body2, .MuiTypography-h5': {
    textWrap: 'nowrap',
    color: llmCallErr ? paletteOptions.severity?.medium : theme.palette.grey[600]
  },

  '.MuiSvgIcon-root': {
    color: paletteOptions.severity?.medium,
    display: llmCallErr ? 'auto' : 'none'
  }
}));

export const SampleDetailsDialogSelectionHeaderContainer = styled(Stack)({
  flexDirection: 'row',
  gap: '6px'
});

export const SampleDetailsDialogSelectionsBranchLine = styled(Stack)(({ theme }) => ({
  width: '16px',
  height: '2px',
  margin: 'auto 0',
  background: theme.palette.grey[200]
}));
