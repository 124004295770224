import { Stack, styled } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';

import { StyledContainer, StyledDropdown, StyledText } from '@/components/lib';

export const AppFiltersContainer = styled(StyledContainer)({
  padding: '0',
  flexWrap: 'wrap',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  '.css-i4bv87-MuiSvgIcon-root': { cursor: 'pointer', margin: '6px -12px 0 0' }
});

export const AppFiltersInnerContainer = styled(StyledContainer)({
  gap: '16px',
  padding: '0',
  width: 'auto',
  flexWrap: 'wrap',
  columnGap: '44px',
  marginLeft: '8px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'right'
});

export const AppFiltersVersionsDropdown = styled(StyledDropdown)({
  width: '200px'
});

export const AppFiltersInnerItemContainer = styled(Stack)({
  gap: '12px',
  flexDirection: 'row',
  alignItems: 'center'
});

export const StyledLabelText = styled(StyledText)({ marginLeft: '0px' });

export const AppFiltersEnvContainer = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  gap: '12px'
});

export const StyledRefreshIcon = styled(RefreshIcon)(({ theme }) => ({
  cursor: 'pointer',
  fill: theme.palette.primary.main,
  transition: 'opacity ease 0.3s',

  ':hover': {
    opacity: 0.7
  }
}));
