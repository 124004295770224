import React, { ReactNode } from 'react';

import { BoxProps } from '@mui/material';

import { StyledRotateContainer } from './RotateContainer.styles';

interface RotateContainerProps extends BoxProps {
  rotate: boolean;
  children: ReactNode;
}

export const RotateContainer = ({ rotate, children, ...otherProps }: RotateContainerProps) => {
  return (
    <StyledRotateContainer rotate={rotate} {...otherProps}>
      {children}
    </StyledRotateContainer>
  );
};
