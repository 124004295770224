import React from 'react';

import { useRouter } from 'next/router';

import { ListAltOutlined } from '@mui/icons-material';

import { routes } from '@/helpers/routes';

import {
  SamplesNoVersionSelectedContainer,
  SamplesNoVersionSelectedButton,
  StyledImageContainer,
  StyledTitleText,
  StyledSubTitleText
} from './SamplesNoVersionSelected.styles';

import { constants } from './samplesNoVersionSelected.constants';

const { noVersion, createVersion, here } = constants;

export const SamplesNoVersionSelected = () => {
  const { push } = useRouter();

  const handleGoToVersionsPage = () => push(routes.config.versions);

  return (
    <SamplesNoVersionSelectedContainer>
      <StyledImageContainer>
        <ListAltOutlined fontSize="large" color="primary" />
      </StyledImageContainer>
      <StyledTitleText text={noVersion} type="h2" />
      <StyledSubTitleText
        text={
          <>
            {createVersion}
            <SamplesNoVersionSelectedButton
              text={here}
              onClick={handleGoToVersionsPage}
              data-testid="noVersionSelected"
              type="h3"
            />
          </>
        }
        type="h3"
      />
    </SamplesNoVersionSelectedContainer>
  );
};
