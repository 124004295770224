import { alpha, styled } from '@mui/material';

import { StyledContainer } from '@/components/lib';

export const SamplesTranslationSelectionContainer = styled(StyledContainer, {
  shouldForwardProp: prop => prop !== 'translation'
})<{ translation?: boolean }>(({ translation, theme }) => ({
  gap: '12px',
  margin: '12px 0',
  cursor: 'pointer',
  padding: '4px 8px',
  transition: '0.3s',
  borderRadius: '8px',
  alignItems: 'center',
  flexDirection: 'row',
  background: translation ? alpha(theme.palette.primary.main, 0.1) : 'transparent',
  border: `2px solid ${translation ? theme?.palette?.primary?.main : theme.palette.grey[200]}`,

  '.MuiTypography-subtitle2': {
    color: theme.palette.primary.main
  },

  '.MuiSvgIcon-root': {
    padding: '4px',
    borderRadius: '50%',
    color: theme.palette.primary.main,
    background: theme.palette.grey[200]
  },

  '&:hover': { opacity: 0.8 }
}));
