import { styled } from '@mui/material';

import { StyledContainer } from '@/components/lib';

export const StyledErrContainer = styled(StyledContainer)({
  width: '450px',
  margin: '10vh auto',
  justifyContent: 'center',
  textAlign: 'center',
  gap: '36px',
  alignItems: 'center'
});
