import { Stack, styled } from '@mui/material';

interface StyledContentCopyContainerProps {
  is_link_copied: string;
}

export const StyledContentCopyContainer = styled(Stack)<StyledContentCopyContainerProps>(
  ({ theme, is_link_copied }) => ({
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '8px',
    cursor: 'pointer',

    ' svg': {
      width: '17px',
      fill: is_link_copied === 'true' ? theme.palette.grey[700] : theme.palette.grey[500]
    },

    ' p': {
      marginLeft: '2px',
      color: is_link_copied === 'true' ? theme.palette.grey[700] : theme.palette.grey[500]
    },

    ':hover': {
      ' svg': {
        fill: theme.palette.grey[700]
      },
      ' p': {
        color: theme.palette.grey[700]
      }
    }
  })
);
