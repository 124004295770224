import React from 'react';

import dayjs from 'dayjs';

import { ArrowBackIosRounded, ClearRounded } from '@mui/icons-material';

import { AnnotationCell } from '../../SamplesAnnotationCell/SamplesAnnotationCell';

import { StyledText, StyledToolTip } from '@/components/lib';
import {
  StyledAnnotationTitle,
  StyledContainer,
  StyledHeaderContainerBase,
  StyledIconButton,
  StyledPaginationButton,
  StyledPaginationContainer,
  StyledHeaderContainerSecondary,
  StyledAnnotationCellContainer,
  StyledAnnotationCellText,
  StyledHeaderInteractionIdContainer
} from './SampleDetailsDialogHeader.styles';

import { InteractionSchema } from '@/helpers/services/api';
import { getMilliseconds } from '@/helpers/utils/getMilliseconds';

import { constants } from './sampleDetailsDialogHeader.constants';
import { SampleDetailsDialogHeaderCopy } from './SampleDetailsDialogHeaderCopy/SampleDetailsDialogHeaderCopy';

const { backButtonLabel, nextButtonLabel, similarityLinkLabel, interactionIdText, paginationText } = constants;

interface SampleDetailsDialogHeaderProps {
  versionId: number;
  pageCount: number;
  currentPage: number;
  iscComparisonView?: boolean;
  interaction: InteractionSchema;
  isAnnotationInteraction?: boolean;
  closeDialog: () => void;
  setIsComparisonView: (isComparisonView: boolean) => void;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  updateAnnotation?: (interactionId: string, annotation: string, reason: string, isEstimated?: boolean) => void;
  setSimilarInteractionToCompare: (interaction: {
    application_version_id: number;
    user_interaction_id: string;
  }) => void;
}

export const SampleDetailsDialogHeader = (props: SampleDetailsDialogHeaderProps) => {
  const {
    interaction,
    pageCount,
    currentPage,
    versionId,
    iscComparisonView,
    isAnnotationInteraction,
    closeDialog,
    setCurrentPage,
    updateAnnotation,
    setIsComparisonView,
    setSimilarInteractionToCompare
  } = props;

  const reason = interaction?.annotation?.reason;
  const interactionId = interaction?.user_interaction_id;
  const similarInteraction = interaction?.annotation?.similarity_reason;
  const header = `${dayjs(interaction?.interaction_datetime).format('MMM DD, LT')} ${getMilliseconds(
    interaction?.started_at,
    interaction?.finished_at
  )}`;

  const handleNextPage = () => setCurrentPage((prev: number) => Math.min(prev + 1, pageCount - 1));

  const handlePrevPage = () => setCurrentPage((prev: number) => Math.max(prev - 1, 0));

  const handleShowSimilarInteraction = () => {
    setSimilarInteractionToCompare({
      application_version_id: Number(similarInteraction?.version_id),
      user_interaction_id: `${similarInteraction?.user_interaction_id}`
    });
    setIsComparisonView(true);
  };

  return (
    <StyledContainer>
      <StyledHeaderContainerBase>
        {iscComparisonView && <ArrowBackIosRounded onClick={() => setIsComparisonView(false)} />}
        <StyledText text={header} type="h2" />
        {interactionId && (
          <StyledHeaderInteractionIdContainer>
            <StyledAnnotationTitle text={interactionIdText(interactionId)} />
            {!isAnnotationInteraction && (
              <SampleDetailsDialogHeaderCopy
                currentPage={currentPage}
                interactionId={interactionId}
                versionId={versionId}
              />
            )}
          </StyledHeaderInteractionIdContainer>
        )}
      </StyledHeaderContainerBase>
      <StyledHeaderContainerSecondary>
        {!iscComparisonView && reason && (
          <StyledToolTip
            text={reason}
            link={{
              label: similarityLinkLabel,
              onClickOverride: similarInteraction?.user_interaction_id ? handleShowSimilarInteraction : undefined
            }}
          >
            <StyledAnnotationCellContainer>
              <StyledAnnotationCellText text={reason} />
            </StyledAnnotationCellContainer>
          </StyledToolTip>
        )}
        {!iscComparisonView && (!!updateAnnotation || isAnnotationInteraction) && (
          <StyledAnnotationCellContainer>
            <AnnotationCell
              key={interaction?.user_interaction_id}
              updateAnnotation={updateAnnotation}
              annotation={interaction?.annotation}
              interactionId={interaction?.user_interaction_id}
              estAnnotation={interaction?.estimated_annotation}
              disableTooltip
            />
          </StyledAnnotationCellContainer>
        )}
        {!!pageCount && (
          <StyledPaginationContainer>
            <StyledPaginationButton label={backButtonLabel} onClick={handlePrevPage} disabled={currentPage === 0} />
            <StyledText text={paginationText(currentPage, pageCount)} type="bodyBold" />
            <StyledPaginationButton
              label={nextButtonLabel}
              onClick={handleNextPage}
              disabled={currentPage === pageCount - 1}
            />
          </StyledPaginationContainer>
        )}
        <StyledIconButton onClick={closeDialog}>
          <ClearRounded />
        </StyledIconButton>
      </StyledHeaderContainerSecondary>
    </StyledContainer>
  );
};
