export const getMilliseconds = (startTime?: string | null, endTime?: string | null) => {
  if (startTime && endTime) {
    const startTimestamp = Date.parse(startTime);
    const endTimestamp = Date.parse(endTime);

    if (!isNaN(startTimestamp) && !isNaN(endTimestamp)) {
      const duration = endTimestamp - startTimestamp;
      const formattedDuration = duration.toLocaleString();

      return `| ${formattedDuration}ms`;
    } else {
      return '';
    }
  } else {
    return '';
  }
};
