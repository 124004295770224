import { styled, Box } from '@mui/material';

import { spinAnimation } from '@/helpers/animations';

interface RotateContainerProps {
  rotate: boolean;
}

export const StyledRotateContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'rotate'
})<RotateContainerProps>(({ rotate }) => ({
  animation: rotate ? `${spinAnimation} 1.2s infinite linear` : ''
}));
