import { Divider, Stack, styled } from '@mui/material';
import { HelpOutline, UpdateRounded } from '@mui/icons-material';

import { StyledThumbs } from '@/components/lib';

import { fadeInAnimation } from '@/helpers/animations';

interface SharedProps {
  expanded?: boolean;
  active?: boolean;
}

export const StyledContainerBase = styled(Stack)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
});

export const StyledContainer = styled(Stack, {
  shouldForwardProp: prop => prop !== 'expanded' && prop !== 'active'
})<SharedProps>(({ theme }) => ({
  width: '176px',
  height: '127px',
  padding: '12px',
  border: `2px solid ${theme.palette.grey[200]}`,
  borderRadius: '8px',
  transition: '0.4s',
  animation: `${fadeInAnimation} 0.5s ease`,

  '&:hover': {
    background: theme.palette.grey[200]
  }
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: '9px 0',
  border: `1.5px solid ${theme.palette.grey[300]}`
}));

export const StyledThumb = styled(StyledThumbs)({
  cursor: 'pointer',
  transition: 'all ease 0.3s',

  '&:hover': {
    opacity: 0.7
  }
});

export const StyledHelp = styled(HelpOutline, {
  shouldForwardProp: prop => prop !== 'active'
})<SharedProps>(({ theme, active }) => ({
  cursor: 'pointer',
  fill: active ? theme.palette.grey[600] : theme.palette.grey[400],
  transition: 'all ease 0.3s',

  '&:hover': {
    opacity: 0.7
  }
}));

export const StyledPending = styled(UpdateRounded, {
  shouldForwardProp: prop => prop !== 'active'
})<SharedProps>(({ theme, active }) => ({
  cursor: 'pointer',
  fill: active ? theme.palette.grey[600] : theme.palette.grey[400],
  transition: 'all ease 0.3s',

  '&:hover': {
    opacity: 0.7
  }
}));
