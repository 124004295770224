import React from 'react';

import {
  StyledSamplesDetailsBodyItemTag,
  StyledSamplesDetailsBodyItemTagLink
} from './SamplesDetailsBodyItemTag.styles';

import { StepSchema } from '@/helpers/services/api';
import { capitalizeFirstLetter } from '@/helpers/utils/capitalizeFirstLetter';

interface SamplesDetailsBodyItemTagProps {
  index: string;
  section: StepSchema;
}

export const SamplesDetailsBodyItemTag = (props: SamplesDetailsBodyItemTagProps) => {
  const { index, section } = props;

  const displayValue = section?.attributes ? `${section?.attributes[index]}` : '';

  const isLink = /^(https?:\/\/|www\.)\S+/i.test(displayValue);

  if (isLink) {
    const prefix = capitalizeFirstLetter(`${index} = `);

    return (
      <StyledSamplesDetailsBodyItemTagLink target="_blank" rel="noopener noreferrer" href={displayValue}>
        <span className="prefix">{prefix}</span>
        {displayValue}
      </StyledSamplesDetailsBodyItemTagLink>
    );
  }

  return <StyledSamplesDetailsBodyItemTag text={`${index} = ${displayValue}`} />;
};
